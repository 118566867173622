import React from "react";

function LoginTab() {
  return (
    <div>
      <div className="flex items-center justify-center h-[350px] bg-darkslategray-100 rounded-md">
        <div className="grid grid-rows-4 content-center">
          <div class="relative z-0  px-2 w-full group">
            <label
              for="first_name"
              class="font-md font-bold  text-[11px]  text-silver
                    bg-darkslategray-100 relative px-1  top-2 left-3 w-auto group-focus-within:text-secondary "
            >
              Username
            </label>
            <input
              type="text"
              name="username"
              id="first_name"
              className="h-6 text-10 border-solid bg-transparent border border-silver text-gray-900 text-sm rounded-lg focus:ring-lightskyblue focus:border-lightskyblue block w-[386px] 
              p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lightskyblue dark:focus:border-lightskyblue"
              required=""
              placeholder="Machina"
            />
          </div>

          <div class="relative z-0  px-2 w-full group">
            <label
              for="password"
              class="font-md font-bold  text-[11px]  text-silver
                    bg-darkslategray-100 relative px-1  top-2 left-3 w-auto group-focus-within:text-secondary "
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className="h-6 text-10 border-solid  bg-transparent border border-silver text-gray-900 text-sm rounded-lg focus:ring-lightskyblue focus:border-lightskyblue block w-[386px] 
              p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lightskyblue dark:focus:border-lightskyblue"
              required=""
              placeholder="machinannna"
            />
          </div>

          <button class="py-3 mt-4 px-2 w-full rounded-md bg-lightskyblue text-white text-xl">
            Login
          </button>
          <div className="flex justify-center">
            <p className="w-[333px] [font-family:'Inter-Bold',Helvetica] font-bold text-[14px] text-center tracking-[0] leading-[19.6px] whitespace-nowrap">
              <span className="text-secondary">Login </span>
              <span className="text-white">or</span>
              <span className="text-secondary"> Register </span>
              <span className="text-white">to start playing.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginTab;
