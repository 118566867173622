import React from "react";

function PlayersTab() {
  return (
    <div>
      <div className="w-full flex flex-col h-64 overflow-hidden" id="chat">
        <div className="messages flex-1 overflow-y-scroll border-box">
          <table className="table-fixed w-full text-sm">
            <thead className="bg-gray-900 text-white">
              <tr>
                <th>USER</th>
                <th>@</th>
                <th>Amount</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody className="text-center text-white">
              <tr>
                <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                <td>Malcolm Lockyer</td>
                <td>1961</td>
              </tr>
              <tr>
                <td>Witchy Woman</td>
                <td>The Eagles</td>
                <td>1972</td>
              </tr>
              <tr>
                <td>Shining Star</td>
                <td>Earth, Wind, and Fire</td>
                <td>1975</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="grid grid-cols-2 text-sm justify-around">
          <p className="text-white">
            Online: <span className="text-yellow-300">100</span>
          </p>
          <p className="text-white">
            Playing: <span className="text-yellow-300">120</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PlayersTab;
