import { useAxios } from "../../../context/useAxios"
import { useState, useEffect } from "react";
// import LoadingSpinner from "../../components/LoadingSpinner";
// import { USER_LOGIN } from "../../queries.js/gqlQueries";
import { toast } from "react-toastify";
import Modal from "../wallet/Modal";
// import PhoneInput from "./PhoneInput";

const LoginPage = ({ onClose }) => {
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Password, setPassword] = useState("");
  const [ForgotPassword, setForgotPassword] = useState(false);

  // axios post method
  const [{ data, loading, error }, login] = useAxios({
    method: 'post',
    url: '/api/user/loginPlayer',
  }, { manual: true });
  useEffect(() => { });

  const validateForm = () => {
    if (!PhoneNumber.match("^254[17][0-9]{8}$")) {
      return "Please provide valid phone number eg. 254712XXXXXX";
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      return toast.error(validationError);
    }
    const user = await login({
      data : {
        password: Password,
        phoneNumber: PhoneNumber,
      },
    }).then(async (res) => {
      console.log(res)
      await localStorage.setItem("Token", res.data.token);
      toast.success("Login Succesful");
      window.location.reload();
      onClose();
    });
  };

  return (
    <div className="bg-white p-5 pb-5 pr-5 rounded rounded-lg">
      <h2 className="font-bold mt-0 text-center uppercase">LOGIN</h2>
      {error && <p className="text-center text-red">{error.message}</p>}
      {loading && <p>Loading</p> }
      <form className="m-4" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="phone" className="block text-sm font-medium">
            Phone Number
          </label>
          <div className="relative mt-0 rounded-md shadow-sm text-midnight">
            <input
              type="text"
              name="phone"
              value={PhoneNumber}
              onChange={(e) =>
                setPhoneNumber(
                  e.target.value.replace(/^0+/, "254").replace("+", "")
                )
              }
              id="phone"
              required
              className="block w-full rounded-md bg-transparent border border-silver border-solid focus:ring-lightskyblue focus:border-lightskyblue text-sm"
              placeholder="e.g, 254712XXXXXX"
            />
          </div>
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium mt-4">
            Password
          </label>
          <div className="relative mt-0 rounded-md shadow-sm text-midnight">
            <input
              type="password"
              name="password"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              required
              className="block w-full rounded-md bg-transparent border border-silver border-solid focus:ring-lightskyblue focus:border-lightskyblue text-sm"
            />
          </div>
        </div>
        <p
          className="mt-0 pt-1 text-right text-sm text-info hover:cursor-pointer"
          onClick={() => setForgotPassword(true)}
        >
          Forgot Password?
        </p>

        {/* submit button */}
        <div>
          <button
            type="submit"
            className=" hover:cursor-pointer w-full rounded-md border border-transparent bg-steelblue mt-4 py-2 px-4 text-lg text-white hover:bg-info focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
          >
            LOGIN
          </button>
        </div>
      </form>
      <p className="text-sm text-center">
        By using this platform I attest that I am at least 18 years old and have
        read and agree to the{" "}
        <span className="text-info hover:cursor-pointer ">
          {" "}
          Terms of Service.
        </span>
      </p>

      <p className="text-sm text-center">
        No Account ?{" "}
        <span className="text-info hover:cursor-pointer ">Sign up.</span>
      </p>
      {/* <Modal open={ForgotPassword} onClose={() => setForgotPassword()}>
        <PhoneInput />
      </Modal> */}
    </div>
  );
};

export default LoginPage;
