import React from "react";
import { Tab } from "@headlessui/react";
import ChatTab from "./ChatTab";
import HistoryTab from "./HistoryTab";
import PlayersTab from "./PlayersTab";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TabPanel = () => {
  return (
    <div className="rounded-t-md mt-3">
      <Tab.Group vertical>
        {/* bg-transparent border border-lightskyblue border-px border-solid text-white mx-1 h-8 focus:bg-lightskyblue w-full focus:font-bold focus:text-primary */}
        <Tab.List className="grid grid-cols-3 space-x-1 text-white  h-[50px] bg-gray-3 px-2 rounded-t-md">
          <Tab
            className={({ selected }) =>
              classNames(
                "border border-lightskyblue border-px border-solid my-2 rounded-tl-md",
                selected
                  ? "bg-lightskyblue font-bold text-primary focus:outline-none "
                  : "text-white bg-transparent "
              )
            }
          >
            HISTORY
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "border border-lightskyblue border-px border-solid my-2",
                selected
                  ? "bg-lightskyblue font-bold text-primary focus:outline-none "
                  : "text-white bg-transparent "
              )
            }
          >
            CHAT
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                "border border-lightskyblue border-px border-solid my-2 ml-2 rounded-tr-md",
                selected
                  ? "bg-lightskyblue font-bold text-primary focus:outline-none "
                  : "text-white bg-transparent "
              )
            }
          >
            PLAYERS
          </Tab>
        </Tab.List>
        <Tab.Panels className="bg-darkslategray-100 rounded-b-md">
          <Tab.Panel>
            <HistoryTab />
          </Tab.Panel>
          <Tab.Panel>
            <ChatTab />
          </Tab.Panel>
          <Tab.Panel>
            <PlayersTab />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabPanel;
