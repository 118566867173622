import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LuLogOut } from "react-icons/lu";
import Wallet from "./pages/wallet/Wallet";
import Modal from "./pages/wallet/Modal";
import { socket } from "../context/socket";
// import Account from "./pages/Account";
// import Faqs from "./pages/wallet/Faqs";
import Logout from "./pages/auth/Logout";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";

function AppBar() {
  const [WalletOpen, setWalletOpen] = useState(false);
  const [AccountOpen, setAccountOpen] = useState(false);
  const [LogoutOpen, setLogoutOpen] = useState(false);
  const [LoginOpen, setLoginOpen] = useState(false);
  const [RegisterOpen, setRegisterOpen] = useState(false);
  const [FAQSOpen, setFAQSOpen] = useState(false);
  const [MenuOpen, setMenuOpen] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const userData = useSelector((state) => state.auth.user);

  function toggleMenu() {
    setMenuOpen(!MenuOpen);
  }


  return (
    <>
      <nav class="bg-steelblue">
        <div class="px-5 md:px-20">
          <div class="flex justify-between">
            <div class="flex space-x-4">
              <div>
                <div
                  //   href="#"
                  class="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900"
                >
                  <img className="h-10 mr-1" alt="" src="/logo.png" />
                </div>
              </div>
            </div>

            <div class="hidden md:flex items-center space-x-3">
              {isAuth && (
                <>
                  <button
                    className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300"
                    onClick={() => setWalletOpen(true)}
                  >
                    Wallet
                  </button>
                  <button className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300">
                    Affiliate Program
                  </button>
                  <button
                    className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300"
                    onClick={() => setFAQSOpen(true)}
                  >
                    Contact Us
                  </button>
                  <button
                    className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300"
                    onClick={() => setAccountOpen(true)}
                  >
                    {userData.username}
                  </button>
                  <button
                    className="py-1 px-4 border border-purple m-2 rounded-md   hover:bg-purple text-yellow hover:text-orange transition duration-300"
                    onClick={() => setWalletOpen(true)}
                  >
                    KSH. {userData.account.balance.toLocaleString()}
                  </button>
                  <button
                    className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300 flex items-center"
                    onClick={() => setLogoutOpen(true)}
                  >
                    <LuLogOut className="w-4 h-4 mr-1" />
                    Logout
                  </button>
                </>
              )}
              {!isAuth && (
                <>
                  <button
                    class="hover:cursor-pointer py-2 px-8 rounded-md bg-transparent border text-white border-solid focus:border-white"
                    onClick={() => setLoginOpen(true)}
                  >
                    Login
                  </button>
                  <button
                    class="hover:cursor-pointer py-2 px-8 rounded-md bg-lightskyblue text-white"
                    onClick={() => setRegisterOpen(true)}
                  >
                    Register
                  </button>
                </>
              )}
            </div>
            {/* <!-- mobile button goes here -->  */}
            <div className="md:hidden flex items-center">
              {!isAuth && (
                <>
                  <button
                    onClick={() => setLoginOpen(true)}
                    className="hover:cursor-pointer py-2 px-8 rounded-md bg-transparent border text-white border-solid focus:border-white"
                  >
                    Login
                  </button>
                  <button
                    className="hover:cursor-pointer py-2 px-8 rounded-md bg-lightskyblue text-white"
                    onClick={() => setRegisterOpen(true)}
                  >
                    Register
                  </button>
                </>
              )}
              {isAuth && (
                <button
                  className="py-1 px-4 border border-purple m-2 rounded-md   hover:bg-purple text-yellow hover:text-orange transition duration-300"
                  onClick={() => setWalletOpen(true)}
                >
                  KSH. {userData.account.balance.toLocaleString()}
                </button>
              )}
              {isAuth && (
                <button
                  className="mobile-menu-button bg-transparent"
                  onClick={() => toggleMenu(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    style={{ color: "#ffff" }}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              )}
            </div>

            
          </div>{/* <!-- mobile menu --> */}
            {MenuOpen && (
              <div className="mobile-menu md:hidden flex flex-row m-0">
                {isAuth && (
                  <>
                    <button
                      className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300"
                      onClick={() => setWalletOpen(true)}
                    >
                      Wallet
                    </button>
                    <button className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300">
                      Affiliate Program
                    </button>
              
                    <button
                      className="py-1 px-4  m-2 bg-transparent hover:bg-info rounded-md text-white transition duration-300"
                      onClick={() => setAccountOpen(true)}
                    >
                      {userData.username}
                    </button>
                    <button
                      className="py-1 px-4 border border-purple m-2 rounded-md   hover:bg-purple text-yellow hover:text-orange transition duration-300"
                      onClick={() => setLogoutOpen(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 21c4.411 0 8-3.589 8-8 0-3.35-2.072-6.221-5-7.411v2.223A6 6 0 0 1 18 13c0 3.309-2.691 6-6 6s-6-2.691-6-6a5.999 5.999 0 0 1 3-5.188V5.589C6.072 6.779 4 9.65 4 13c0 4.411 3.589 8 8 8z"></path>
                        <path d="M11 2h2v10h-2z"></path>
                      </svg>
                    </button>
                  </>
                )}
              </div>
            )}

        </div>
      </nav>

      <Modal open={WalletOpen} Title={"Wallet"} onClose={() => setWalletOpen()}>
        <Wallet socket={socket} />
      </Modal>
      <Modal open={FAQSOpen} onClose={() => setFAQSOpen()}>
        {/* <Faqs onClose={() => setFAQSOpen()} /> */}
        faq
      </Modal>
      <Modal open={AccountOpen} onClose={() => setAccountOpen()}>
        {/* <Account /> */}
        account
      </Modal>
      <Modal open={LogoutOpen} onClose={() => setLogoutOpen()}>
        <Logout onClose={() => setLogoutOpen()} />
      </Modal>
      <Modal open={LoginOpen} onClose={() => setLoginOpen()}>
        <LoginPage onClose={() => setLoginOpen()} socket={socket} />
      </Modal>
      <Modal
        open={RegisterOpen}
        Title={"Register"}
        onClose={() => setRegisterOpen()}
      >
        <RegisterPage onClose={() => setRegisterOpen()} socket={socket} />
      </Modal>
    </>
  );
}

export default AppBar;
