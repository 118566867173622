import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { socket } from "../../../context/socket";

const Deposit = () => {
  const userData = useSelector((state) => state.auth.user);
  const [DepositAmount, setDepositAmount] = useState(200);
  const [RefCode, setRefCode] = useState();

  const handleDeposit = async (e) => {
    e.preventDefault();
    if (DepositAmount <= 20) {
      return toast.error("Minimum deposit Amount is Kshs. 50");
    }
    socket.emit(
      "transaction_deposit",
      { amount: Number(DepositAmount) },
      (response) => {
        return toast(response.message, { type: response.status });
      }
    );
  };
  const handlePending = async (e) => {
    e.preventDefault();
  };

  const [IsExpress, setIsExpress] = useState(true);
  return (
    <div>
      <div className="m-4 grid grid-cols-2 rounded-md justify-around ">
        <button
          className={
            IsExpress
              ? "py-1 px-4  m-2 bg-steelblue hover:bg-info rounded-md text-white text-lg transition duration-300 border border-solid border-steelblue"
              : "py-1 px-4 m-2 bg-transparent hover:bg-info border border-solid text-lg border-steelblue rounded-md text-primary transition duration-300"
          }
          onClick={() => setIsExpress(true)}
        >
          M-pesa Express
        </button>
        <button
          className={
            !IsExpress
              ? "py-1 px-4 m-2 bg-steelblue hover:bg-info rounded-md text-white text-lg transition duration-300 border border-solid border-steelblue"
              : "py-1 px-4 m-2 bg-transparent  rounded-md border border-solid text-lg border-steelblue text-primary transition duration-300"
          }
          onClick={() => setIsExpress(false)}
        >
          Paybill Instructions
        </button>
      </div>
      {IsExpress ? (
        <form
          className="px-4 mx-4"
          onSubmit={handleDeposit}
        >
          <div className="">
            <label
              htmlFor="deposit"
              className="block text-lg text-steelblue font-medium"
            >
              Amount (KES)
            </label>
            <div className="relative mt-1 ">
              <input
                type="number"
                name="deposit"
                value={DepositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                id="deposit"
                className="block w-full pr-0 rounded-md bg-white border border-silver border-solid focus:ring-lightskyblue focus:border-lightskyblue text-sm"
                min={20}
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full rounded-md border border-transparent bg-steelblue my-4 py-2 px-4 text-lg text-white hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-yellow focus:ring-offset-2"
          >
            DEPOSIT
          </button>
        </form>
      ) : (
        <div className="mx-4 px-4 text-steelblue">
          NOTE:
          <ul className="list-disc pl-5 text-sm mb-5">
            <li>Minimum deposit amount is KES 100</li>
            <li>
              You can ONLY deposit using the same phone number you use to login.
            </li>
          </ul>
          STEPS:
          <ul className="list-decimal pl-5 text-sm">
            <li>Go to M-PESA on your phone </li>
            <li>Select Pay Bill option </li>
            <li>
              Enter Business no.{" "}
              <span className="text-orange text-lg font-bold">{646999}</span> (
              {"LUCKY BOX"}){" "}
            </li>
            <li>
              Enter Account no.{" "}
              <span className="text-orange text-lg font-bold">{"BKFD"}</span>{" "}
            </li>
            <li>Enter the Amount </li>
            <li>Enter your M-PESA PIN and Send</li>
          </ul>
        </div>
      )}
      <div>
      <div className="mx-4 px-4 text-steelblue">
        Your deposit is processed within 2 minutes. When there are delays, <br/>enter
        the mpesa transaction code in the form below and press Verify.
        <hr className="" />
        For assistance, contact us on{" "}
        <span className="text-orange font-bold">0793003346</span>
      </div>
      <div className="border-2 border-white  mx-4 rounded-md px-4">
        <h1 className="font-bold mb-2 mt-4 text-2xl text-steelblue">
          Verify pending mpesa deposit
        </h1>
        <p className="text-steelblue">
          We automatically verify all mpesa transactions and you may never have
          to use this step.<br/> ONLY use this if your deposit is delayed for more
          than a minute.
        </p>
        <form className="mt-4" onSubmit={handlePending}>
          <label
            htmlFor="referenceNO"
            className="block text-sm font-medium text-steelblue"
          >
            MPesa Reference Number
          </label>
          <div className="relative px-4 mt-1 rounded-md shadow-sm">
            <input
              type="text"
              name="referenceNO"
              value={RefCode}
              onChange={(e) => setRefCode(e.target.value)}
              id="referenceNO"
              className="w-full rounded-md border-purple-light focus:border-purple-dark focus:ring-purple-dark text-lg"
              placeholder="e.g, OLBX10BTY"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full justify-center rounded-md border border-transparent bg-steelblue my-4 py-2  text-lg text-white hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-yellow focus:ring-offset-2"
          >
            VERIFY
          </button>
        </form>
      </div>
      </div>
      </div>
  );
};

export default Deposit;
