import React, { useState } from "react";
import { FaXTwitter } from "react-icons/fa6";
import {
  BiLogoInstagram,
  BiLogoYoutube,
  BiLogoLinkedinSquare,
  BiLogoFacebookSquare,
} from "react-icons/bi";
import { useSelector } from "react-redux";
import Modal from "./pages/wallet/Modal";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import { socket } from "../context/socket";

function Footer() {
  const [LoginOpen, setLoginOpen] = useState(false);
  const [RegisterOpen, setRegisterOpen] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuth);

  return (
    <div>
      <div className="h-[100px] w-full bg-steelblue flex items-center bottom-0">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div className="flex items-center">
              {!isAuth &&
                <p className="[font-family:'Inter-Bold',Helvetica] font-bold text-[14px] text-center whitespace-nowrap">
                  <span className="text-secondary cursor-pointer" onClick={() => setLoginOpen(true)}>Login </span>
                  <span className="text-white">or</span>
                  <span className="text-secondary cursor-pointer" onClick={() => setRegisterOpen(true)}> Register </span>
                  <span className="text-white">to start playing.</span>
                </p>
              }
            </div>
            {!isAuth &&
              <div class="flex items-center space-x-3">
                <button class="py-2 px-8 rounded-md bg-transparent border text-white border-solid border-white" onClick={() => setLoginOpen(true)}>
                  Login
                </button>
                <button class="py-2 px-8 rounded-md bg-lightskyblue text-white" onClick={() => setRegisterOpen(true)}>
                  Register
                </button>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="container mx-0 mt-5">
        <div className="md:grid md:grid-cols-3 w-screen">
          <div className="flex flex-col items-center text-white">
            <p className="font-bold text-lg">Learn More</p>
            <p className="m-0 text-sm text-gray">Help Center</p>
            <p className="m-0 text-sm text-gray">Terms & Conditions</p>
            <p className="m-0 text-sm text-gray">Responsible Gaming Policy</p>
          </div>
          <div className="flex flex-col items-center text-white">
            <p className="font-bold text-lg">Play</p>
            <p className="m-0 text-sm text-gray">SafariBust</p>
            <p className="m-0 text-sm text-gray">Vutapesa</p>
            <p className="m-0 text-sm text-gray">LuckyBox</p>
          </div>
          <div className="flex flex-col items-center text-white">
            <p className="font-bold text-lg">Contact Us</p>
            <p className="m-0 text-sm text-gray"> </p>
            <p className="m-0 text-sm text-gray">Telephone: +25471 300 600</p>
            <p className="m-0 text-sm text-gray">Email:support@safarigames.com
            </p>
            <p className="m-0 text-sm text-gray">
              <BiLogoFacebookSquare className="h-[24px] w-[24px] m-2" />
              <BiLogoInstagram className="h-[24px] w-[24px] m-2" />
              <FaXTwitter className="h-[24px] w-[24px] m-2" />
              <BiLogoYoutube className="h-[24px] w-[24px] m-2" />
              <BiLogoLinkedinSquare className="h-[24px] w-[24px] m-2" />
            </p>
          </div>
        </div>
        <div className="text-center text-white text-sm">
          <p>
            Strictly NOT for persons under the age of 18. Please gamble
            responsibly. Licensed by BCLB under licence No. XXXXXXX.
          </p>
          <p className="text-[12px] text-gray p-5">
            © 2023 Safaribust | All Rights Reserved
          </p>
        </div>
      </div>

      <Modal open={LoginOpen} onClose={() => setLoginOpen()}>
        <LoginPage onClose={() => setLoginOpen()} socket={socket} />
      </Modal>
      <Modal
        open={RegisterOpen}
        Title={"Register"}
        onClose={() => setRegisterOpen()}
      >
        <RegisterPage onClose={() => setRegisterOpen()} socket={socket} />
      </Modal>
    </div>
  );
}

export default Footer;
