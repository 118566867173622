import React, { useEffect, useState } from "react";
import { useAxios } from "../../context/useAxios";
import { socket } from "../../context/socket";

const HistoryTab = () => {
  const [boxes, setBoxes] = useState([])
  const [{ data, loading, error }] = useAxios({
    method: 'post',
    url: '/api/user/get_boxes',
  });
  // console.log(data)

  useEffect(() => {
    socket.on("box_histories", (box) => {
      console.log(box)
      const newBoxes = [...boxes, box]
    setBoxes(newBoxes);
    console.log(boxes)
      // getBoxes();
    });
  });
  // if(loading){
  //   return <div>loading</div>
  // }
  return (
    <div>
      <div className="w-full flex flex-col h-64 overflow-hidden" id="chat">
        <div className="flex-1 overflow-y-scroll border-box">
          <table className="table-fixed w-full text-sm">
            <thead className="text-white font-bold text-[18px]">
              <tr>
                <th>BOX 1</th>
                <th>BOX 2</th>
                <th>BOX 3</th>
                <th>BOX 4</th>
                <th>BOX 5</th>
                <th>BOX 6</th>
              </tr>
            </thead>
            <tbody className="text-center text-white">
              {boxes.reverse().map((box) => <>
              <tr className="border-b-2  border-solid border-px border-black-2 hover:bg-lightskyblue">
                <td>{box.box1}</td>
                <td>{box.box2}</td>
                <td>{box.box3}</td>
                <td>{box.box4}</td>
                <td>{box.box5}</td>
                <td>{box.box6}</td>
              </tr>
              </>)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HistoryTab;
