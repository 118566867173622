import { Dialog } from "@headlessui/react";
import { GrClose } from "react-icons/gr";

const Modal = ({ open, onClose, children }) => {
  if (!open) return null;
  return (
    <Dialog open={open} onClose={() => onClose()} className="relative z-50 ">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-midnight/60" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto bg-transparent md:w-1/3">
          <Dialog.Title className="flex justify-end m-0 p-0">
            <button
              className="bg-red-500 md:bg-transparent hover:bg-red-500 text-white font-bold p-2 m-2 z-30 rounded h-10 "
              id="close-button"
              onClick={onClose}
            >
              <GrClose className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="">{children}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Modal;
