import React, { useState } from "react";
import Box from "./Box";

function Boxes() {  

  return (
    <div>
      <div className="grid grid-cols-3 gap-4 px-3">
      {Array.from({ length: 6 }, (_, index) => 
      <Box key={index} box={index+1}/>
      )}
      </div>
      
    </div>
  );
}

export default Boxes;
