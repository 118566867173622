import React, { useState } from "react";
import Moment from "moment";

const ChatTab = () => {
  const [Message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("chat");
  };
  // setMessages(data.chats);

  return (
    <div>
      <div className="w-full flex flex-col h-64 overflow-hidden" id="chat">
        <div className="messages flex-1 overflow-y-scroll border-box px-1 md:px-4">
          <p className="message-content text-yellow text-xs md:text-sm">
            {Moment(Date.now()).format("HH:mm")}{" "}
            <span className="text-orange">username : </span>
            <span className="text-white">message</span>
          </p>
          <div />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row rounded">
            <input
              //   disabled={!isAuth}
              className="flex-1 rounded"
              value={Message}
              placeholder="Message or /Help..."
              type="text"
              onChange={(e) => setMessage(e.target.value)}
              required
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <button
              className="w-16 bg-orange hover:bg-yellow focus:bg-yellow"
              //   disabled={!isAuth}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatTab;
