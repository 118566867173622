import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authenticate, updateUser } from "../../../features/authSlice";
import { useAxios } from "../../../context/useAxios"
import { toast } from "react-toastify";
// import { useMutation } from "@apollo/client";
// import { USER_REGISTER } from "../../queries.js/gqlQueries";
// import LoadingSpinner from "../../components/LoadingSpinner";

const RegisterPage = ({ onClose, socket }) => {
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPButton, setOTPButton] = useState(true);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [FormError, setFormError] = useState(false);
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [timeLeft, setTimeLeft] = useState(null)
  const [resend, setResend] = useState(false)
  const dispatch = useDispatch();


  
  // axios post method
  const [{ data, loading, error }, register] = useAxios({
    method: 'post',
    url: '/api/user/registerPlayer',
  }, { manual: true });

  const [{ data: otpstate, loading:otploading}, getOTP] = useAxios({
    method: 'post',
    url: '/api/user/getOTP',
  }, { manual: true });

  const [{ data: otpverify, loading:verifyloadin, error: verifyError}, verifyOTP] = useAxios({
    method: 'post',
    url: '/api/user/verifyOTP',
  }, { manual: true });
  
const handleGetOPT=async ()=>{
  if (!PhoneNumber.match("^254[17][0-9]{8}$")) {
    return toast.error("Please provide valid phone number eg. 254712XXXXXX");
  }
  try {
    await getOTP({
      data: {
        phoneNumber: PhoneNumber,
      },
    }).then((res) => {
      setOTPButton(false)
      toast.success(res.data.message);
      setTimeLeft(60)
    });
  } catch (error) {
    return
  }
}
// OTP counter 
useEffect(() => {
  if(timeLeft===0){
      setResend(true)
     setTimeLeft("Verify")
  }
  if (timeLeft === "Verify") {
    return    
  };    
  const intervalId = setInterval(() => {
    setTimeLeft(timeLeft - 1);
  }, 1000);
  // clear interval on re-render to avoid memory leaks
  return () => clearInterval(intervalId);
}, [timeLeft]);

const handleVerifyOTP = async ()=>{
  if (!PhoneNumber.match("^254[17][0-9]{8}$")) {
    return toast.error("Please provide valid phone number eg. 254712XXXXXX");
  }
  if(OTP === ""){
    return toast.error("Enter OTP")
  }
  try {
    await verifyOTP({
      data: {
        phoneNumber: PhoneNumber,
        code: OTP
      },
    }).then((res) => {
      console.log(res);
      if(res.data.status === "error"){
        console.log(1223);
        return toast.error(res.data.message);
      }
      toast.success(res.data.message);
      setOTPVerified(true)
    });
  } catch (error) {
    console.log(error);
   return
  }
}
  const validateForm = () => {
    if (!PhoneNumber.match("^254[17][0-9]{8}$")) {
      return "Please provide valid phone number eg. 254712XXXXXX";
    }
    if (Password !== ConfirmPassword) {
      return "Passwords Do Not Match";
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      return setFormError(validationError);
    }
    await register({
      data: {
        username: Username,
        password: Password,
        phoneNumber: PhoneNumber,
      },
    }).then((res) => {
      console.log(res)
      toast.success(`${res.data.message}, Proceed to login`);
      onClose();
    });
  };

  useEffect(() => {
    socket.emit("user_login", (data) => {
      if (!data.isAuth) {
        dispatch(authenticate(data.isAuth));
      }
      dispatch(updateUser(data.user));
      dispatch(authenticate(data.isAuth));
    });
  });

  return (
    <div className="bg-white p-5 pb-5 pr-5 rounded rounded-lg">
      <h2 className="font-bold mt-0 text-center uppercase">REGISTER</h2>
      {loading && <p>Loading</p>}
      {error && <p className="text-center text-red-900">{error.message}</p>}
      <form className="m-4" onSubmit={handleSubmit}>
        {FormError && (
          <ol>
            <li className="text-red-900">{FormError}</li>
          </ol>
        )}
        <div>
          <label htmlFor="phone" className="block text-sm font-medium">
            Phone Number
          </label>
          <div className="relative mt-0 rounded-md shadow-sm text-midnight">
            <input
              type="text"
              value={PhoneNumber}
              onChange={(e) =>
                setPhoneNumber(
                  e.target.value.replace(/^0+/, "254").replace("+", "")
                )
              }
              id="phone"
              className="block w-full rounded-md bg-transparent border border-silver border-solid focus:ring-lightskyblue focus:border-lightskyblue text-sm"
              placeholder="e.g, 254712XXXXXX"
              required
            />
          </div>
          <p className="mt-0 pt-0 text-[10px]">
            Please enter a valid MPESA number. All deposits &amp; withdrawals
            will be made through this number
          </p>
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm font-medium">
            OTP
          </label>
          <div className="grid grid-cols-3 relative mt-0 rounded-md shadow-sm text-midnight ">
            <input
              type="text"
              value={OTP}
              onChange={(e) =>
                setOTP(e.target.value)
              }
              id="otp"
              className="block col-span-2 rounded-md bg-transparent border border-silver border-solid focus:ring-lightskyblue focus:border-lightskyblue text-sm"
              placeholder="Enter OTP"
              required
            />
            {OTPButton ? 
            <button
            type="button"
            className=" hover:cursor-pointer m-2 rounded-md border border-transparent bg-steelblue mt-4 py-2 px-4 text-lg text-white hover:bg-info focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
            onClick={async (e)=>{
              e.preventDefault();
              handleGetOPT()
            }}
          >
            GET OTP
          </button>
          :
          <>
          <button
              type="button"
              disabled={OTPVerified}
              className=" hover:cursor-pointer m-2 disabled:bg-gainsboro rounded-md border border-transparent bg-steelblue mt-4 py-2 px-4 text-lg text-white hover:bg-info focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
              onClick={async (e)=>{
                e.preventDefault();
                handleVerifyOTP()
              }}
            >
              {timeLeft}
            </button>{resend && <p className="w-full text-xl text-steelblue hover:cursor-pointer" onClick={async (e)=>{
                e.preventDefault();
                setResend(false)
                handleGetOPT()
              }}>
            Resend
          </p>}</>}
            
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="username" className="block text-sm font-medium">
            Username
          </label>
          <div className="relative mt-0 rounded-md shadow-sm">
            <input
              type="text"
              value={Username}
              onChange={(e) => setUsername(e.target.value)}
              id="username"
              className="block w-full rounded-md bg-transparent border border-silver border-solid focus:ring-lightskyblue focus:border-lightskyblue text-sm"
              placeholder="e.g, pesa234"
              maxLength="15"
              required
            />
          </div>
          <p className="mt-0 pt-0 text-[10px]">
            A unique public username, eg pesa234. Please note it does not have
            to be your real name
          </p>
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium mt-4">
            Password
          </label>
          <div className="relative mt-0 rounded-md shadow-sm">
            <input
              type="password"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              className="block w-full rounded-md bg-transparent border border-silver border-solid focus:ring-lightskyblue focus:border-lightskyblue text-sm"
              minLength="8"
              required
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="confirm-password"
            className="block text-sm font-medium mt-4"
          >
            Confirm Password
          </label>
          <div className="relative mt-0 rounded-md shadow-sm">
            <input
              type="password"
              value={ConfirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="confirm-password"
              className="block w-full rounded-md border-gray-300 focus:border-purple-500 focus:ring-purple-500 text-lg"
              required
            />
          </div>
        </div>

        {/* submit button */}
        <div>
          <button
            type="submit"
            disabled={!OTPVerified}
            className=" hover:cursor-pointer disabled:bg-gainsboro w-full rounded-md border border-transparent bg-steelblue mt-4 py-2 px-4 text-lg text-white hover:bg-info focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
          >
            LOGIN
          </button>
        </div>
      </form>
      <p className="text-sm text-center">
        By using this platform I attest that I am at least 18 years old and have
        read and agree to the{" "}
        <span className="text-info hover:cursor-pointer  ">
          {" "}
          Terms of Service.
        </span>
      </p>
    </div>
  );
};

export default RegisterPage;
