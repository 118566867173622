import { useDispatch } from "react-redux";
import { socket } from "../context/socket";
import { authenticate, updateUser } from "../features/authSlice";

const OpenBoxes = ({ onClose, boxes }) => {
  const dispatch = useDispatch();

  const doLogout = () => {
    localStorage.removeItem("Token", "username");
    socket.user = false;
    socket.isAuth = false;
    dispatch(updateUser(socket.user));
    dispatch(authenticate(socket.isAuth));
    // onClose();
    window.location.reload();
  };

  return (
    <div>
      <div className="bg-white p-5 pb-5 pr-5 rounded rounded-lg">
        <div className="font-bold mt-0 text-center uppercase">BOX RESULTS</div>
        {/* <p>Are you sure you want to logout?</p> */}
        {boxes.boxes.map(({box})=>{
          <div
          className="w-full text-steelblue p-2 mt-5 rounded-md bg-transparent"
        >
          {box[0]}
        </div>
        })}
        
      </div>
    </div>
  );
};

export default OpenBoxes;
