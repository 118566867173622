import { Tab } from "@headlessui/react";
import Deposit from "./Deposit";
import History from "./History";
import Withdraw from "./Withdraw";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { authenticate, updateUser } from "../../../features/authSlice";
import { socket } from "../../../context/socket";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Wallet = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("wallet fetching");
    socket.emit("user_login", (data) => {
      dispatch(updateUser(data.user));
      dispatch(authenticate(data.isAuth));
    });
  });
  return (
    <div className="bg-steelblue rounded rounded-lg  pt-5">
      <Tab.Group vertical>
        <Tab.List className="grid grid-cols-3 space-x-1  pt-1 px-1 mx-5">
          <Tab
            className={({ selected }) =>
              classNames(
                "hover:cursor-pointer bg-transparent border border-solid focus:border-white text-xl px-6",
                selected ? "bg-white text-teal" : "bg-transparent text-white"
              )
            }
          >
            Deposit
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "hover:cursor-pointer bg-transparent border border-solid focus:border-white text-xl px-6",
                selected ? "bg-white text-teal" : "bg-transparent text-white"
              )
            }
          >
            Withdraw
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "hover:cursor-pointer bg-transparent border border-solid focus:border-white text-xl px-6",
                selected ? "bg-white text-teal" : "bg-transparent text-white"
              )
            }
          >
            History
          </Tab>
          {/* <Tab
            className={({ selected }) =>
              classNames(
                "hover:cursor-pointer bg-transparent border border-solid focus:border-white text-xl px-6",
                selected ? "bg-white text-teal" : "bg-transparent  text-white"
              )
            }
          >
            Bonuses
          </Tab> */}
        </Tab.List>
        <Tab.Panels className="bg-gainsboro rounded-b-md">
          <Tab.Panel>
            <Deposit />
          </Tab.Panel>
          <Tab.Panel>
            <Withdraw />
          </Tab.Panel>
          <Tab.Panel>
            <History />
          </Tab.Panel>
          {/* <Tab.Panel>
            Bonuses
          </Tab.Panel> */}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Wallet;
