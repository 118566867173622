import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import ActionArea from "./components/ActionArea";
import AppBar from "./components/AppBar";
import Boxes from "./components/Boxes";
import Footer from "./components/Footer";
import LoginTab from "./components/LoginTab";
import TabPanel from "./components/TabPanel/TabPanel";

const App = () => {
  const isAuth = true;
  return (
    <div className="w-screen w-full">
      <AppBar />
      <div className="container md:w-[1300px] mx-auto py-5">
        <div className="grid md:grid-cols-2 gap-6">
          <Boxes />
          <div className="hidden md:block">
          {isAuth ? <ActionArea /> : <LoginTab />}
          </div>
        </div>

        <div className="flex items-center my-5 mx-0">
          <hr class="w-[500px] h-px bg-white border-0 rounded"></hr>
          <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-[20px] whitespace-nowrap">
            Test your Luck
          </div>
          <hr class="w-[500px] h-px bg-white border-0 rounded"></hr>
        </div>

        <img className=" w-full md:h-[150px] rounded rounded-md" alt="banner" src="/banner.png" />

        {/* tabs */}
        <TabPanel />
      </div>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={3}
      />
    </div>
  );
};

export default App;
