import moment from "moment";
import React from "react";
// import { useSelector } from "react-redux";

const History = () => {
  // const userData = useSelector((state) => state.auth.user);
  return (
    <div>
      <div className="m-5">
        <table className="table-fixed border-2 m-4 py-5 border-steelblue w-full">
          <thead className="text-left text-steelblue">
            <tr>
              <th>AMOUNT</th>
              <th>@</th>
              <th>DATE</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody className="text-left ">
            {/* {userData.bets.map(({ _id, amount, createdAt, rate, status }) => (
              <tr key={_id} className="border-b border-midnight">
                <td>Kshs. {amount}</td>
                <td className="pl-3">@ {rate}</td>
                <td>{moment(createdAt).fromNow()}</td>
                <td className={status === "win" ? "text-green" : "text-red"}>
                  {status}
                </td>
              </tr>
            ))} */}

            <tr className="border-b border-solid border-px border-black-2 hover:bg-lightskyblue">
              <td>Malcolm Lockyer</td>
              <td>--</td>
              <td>--</td>
              <td>win</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default History;
