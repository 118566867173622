import { useDispatch } from "react-redux";
import { socket } from "../../../context/socket";
import { authenticate, updateUser } from "../../../features/authSlice";

const Logout = ({ onClose }) => {
  const dispatch = useDispatch();

  const doLogout = () => {
    localStorage.removeItem("Token", "username");
    socket.user = false;
    socket.isAuth = false;
    dispatch(updateUser(socket.user));
    dispatch(authenticate(socket.isAuth));
    // onClose();
    window.location.reload();
  };

  return (
    <div>
      <div className="bg-white p-5 pb-5 pr-5 rounded rounded-lg">
        <div className="font-bold mt-0 text-center uppercase">LOGOUT</div>
        <p>Are you sure you want to logout?</p>
        <button
          className="w-full text-white p-2 mt-5 rounded-md bg-steelblue"
          onClick={doLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Logout;
